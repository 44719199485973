<template>
  <div>
    <header
      id="header-modo-ead"
      class="fd-app-mode-ead-header"
    >
      <div
        id="divBannerMeusCursos"
        class="fd-app-mode-ead-header-bg"
        :style="{ backgroundImage: 'url(' + $store.state.fastPlataforma.banner_plataforma + ')', backgroundColor: '#000' }"
      />
      <div class="fd-app-mode-ead-header-overlay">
        <div class="container">
          <div class="row justify-content-between align-items-center">
            <div class="col-sm-12 col-md-12 col-lg-8">
              <h1
                :style="{ color: $store.state.fastPersonalizacao.titulo_banner_color ? $store.state.fastPersonalizacao.titulo_banner_color : '#fff', textShadow: '1px 1px #000' }"
              >
                {{ $store.state.fastPersonalizacao.titulo_banner ? $store.state.fastPersonalizacao.titulo_banner : '' }}
              </h1>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-4">
              <div class="fd-app-mode-ead-header-mural">
                <div class="d-flex">
                  <img v-lazy="require('@/assets/images/app/alarm-icon.png')">
                  <h4>Mural de avisos</h4>
                </div>
                <ul
                  id="listaMuralAvisos"
                  style
                >
                  <li>
                    <a href="#">Nenhum aviso</a>
                  </li>
                </ul>
                <div class="text-right">
                  <a href="#">ver mais</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
    <!-- fd-wrap -->
    <div class="fd-app-wrap">
      <div class="container">
        <!-- breadcrumb -->
        <nav>
          <ul class="fd-app-breadcrumb">
            <li class="fd-app-breadcrumb-item">
              <a
                href="/minhas-plataformas"
                class="aluno_font_color"
              >Minhas Plataformas</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                :href="'/plataforma/' + $route.params.id_plataforma"
                class="aluno_font_color"
              >Plataforma {{ $store.state.fastPlataforma.nome_plataforma }}</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                :href="'/plataforma/' + $route.params.id_plataforma + '/aluno'"
              >Modo aluno</a>
            </li>
            <li class="fd-app-breadcrumb-item active">
              <a href="#">Meus certificados</a>
            </li>
          </ul>
        </nav>
        <!-- /breadcrumb -->
      </div>

      <div class="fd-mode-ead-content">
        <div class="container">
          <div class="row">
            <transition name="slide-fade">
              <component
                :is="HomeInternoAlunoMenuLateral"
                v-if="!isMobile()"
              />
            </transition>
            <div
              id="body_conteudo_modo_ead"
              class="col-sm-12 col-md-12 col-lg-11 fd-mode-ead-content-body"
            >
              <div class="fd-mode-ead-content mt-0">
                <div class="container">
                  <div class="row justify-content-center">
                    <div class="col-sm-12 col-md-12 col-lg-12">
                      <div class="row">
                        <div class="col-sm-12 col-md-12 col-lg-12">
                          <!-- fd-app-welcome -->
                          <section class="fd-app-welcome text-left">
                            <h1>
                              Meus
                              <span>certificados</span>
                            </h1>
                            <p>Veja os certificados conquistados aqui</p>
                            <div class="fd-welcome-form-effect">
                              <img
                                src="require('@/assets/images/form-effect-01.png')"
                                class="mt-2"
                              >
                            </div>
                          </section>
                          <!-- /fd-app-welcome -->
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-12" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /fd-wrap -->
  </div>
</template>

<script>
import { settings } from "@/config";
import methods from "@/methods";

export default {
  name: "HomeInternoAluno",
  mixins: [methods],
  data: function () {
    return {
      fastTemplate: settings.fastTemplate,
      nomeUsuario: "",
      imageUsuario: this.retornaSessao(settings.fastSessaoUsuario).Image,
      nome_plataforma: "",
      banner_plataforma: "",
      logo_plataforma: "",
      // Certificados
      fastCertificados: [],
      fastPersonalizacao: {
        banner_meus_cursos: "",
        chat_on_line: "",
        email_atendimento: "",
        email_atendimento_professor: "",
        email_atendimento_rh: "",
        id_plataforma: 0,
        imagem_informativa: "",
        telefone: "",
        whatsapp: "",
      },
    };
  },
  computed: {
    HomeInternoAlunoMenuLateral() {
      return () =>
        import(
          `@/templates/${this.fastTemplate}/components/HomeInternoAlunoMenuLateral.vue`
        );
    },
  },
  mounted: function () {
    // Captura ID da plataforma pela URL
    if (this.$route.params.id_plataforma) {
      this.$store.state.fastPermissoes.id_plataforma = this.$route.params.id_plataforma;
      this.getFastPersonalizacao();
      this.getPlataformaData();
      this.getPlataformaPermissoesData();
    }
  },
  methods: {
    getPlataformaData() {
      let obj = "";
      // Se dados da plataforma existem na sessão
      if (this.retornaSessao(settings.fastSessaoPlataforma)) {
        obj = this.retornaSessao(settings.fastSessaoPlataforma);
        // Verifica se o id_plataforma da URL é o mesmo da sessão
        if (obj.id_plataforma != this.$route.params.id_plataforma) {
          // Busca na API
          this.buscaAPIPlataforma(this.$route.params.id_plataforma).then(
            (obj) => {
              if (obj.length > 0) {
                sessionStorage.setItem(
                  settings.fastSessaoPlataforma,
                  JSON.stringify(obj[0])
                );
                this.setPlataformaParam(
                  this.retornaSessao(settings.fastSessaoPlataforma)
                );
              }
            }
          );
        } else {
          this.setPlataformaParam(obj);
        }
      } else {
        // Busca na API
        this.buscaAPIPlataforma(this.$route.params.id_plataforma).then(
          (obj) => {
            if (obj.length > 0) {
              sessionStorage.setItem(
                settings.fastSessaoPlataforma,
                JSON.stringify(obj[0])
              );
              this.setPlataformaParam(
                this.retornaSessao(settings.fastSessaoPlataforma)
              );
            }
          }
        );
      }
    },
    setPlataformaParam(obj) {
      this.nome_plataforma = "Plataforma " + obj.nome_plataforma;
      this.banner_plataforma =
        obj.banner_plataforma != null
          ? obj.banner_plataforma
          : this.imagePlataformaBannerDefault;
      this.logo_plataforma =
        obj.logo_plataforma != null
          ? obj.logo_plataforma
          : this.imagePlataformaLogoDefault;
      this.$store.state.fastPermissoes.admin = obj.administrador;
      this.$store.state.fastPermissoes.admin_fast = obj.admin_fast;
    },
    getPlataformaPermissoesData() {
      let obj = "";
      // Se dados da plataforma existem na sessão
      if (this.retornaSessao(settings.fastSessaoPermissoes)) {
        obj = this.retornaSessao(settings.fastSessaoPermissoes);
        if (obj.id_plataforma != this.$route.params.id_plataforma) {
          // Busca na API
          this.buscaAPIPlataformaPermissoes(
            this.$route.params.id_plataforma
          ).then((obj) => {
            if (obj.length > 0) {
              sessionStorage.setItem(
                settings.fastSessaoPermissoes,
                JSON.stringify(obj[0])
              );
              this.setPlataformaPermissoesParam(
                this.retornaSessao(settings.fastSessaoPermissoes)
              );
            }
          });
        } else {
          this.setPlataformaPermissoesParam(obj);
        }
      } else {
        // Busca na API
        this.buscaAPIPlataformaPermissoes(
          this.$route.params.id_plataforma
        ).then((obj) => {
          if (obj.length > 0) {
            sessionStorage.setItem(
              settings.fastSessaoPermissoes,
              JSON.stringify(obj[0])
            );
            this.setPlataformaPermissoesParam(
              this.retornaSessao(settings.fastSessaoPermissoes)
            );
          }
        });
      }
    },
    setPlataformaPermissoesParam(obj) {
      this.$store.state.fastPermissoes.ativo = obj.ativo;
      this.$store.state.fastPermissoes.id_admin_pai = obj.id_admin_pai;
      this.$store.state.fastPermissoes.id_pessoa = obj.id_pessoa;
      this.$store.state.fastPermissoes.professor = obj.professor;
      this.$store.state.fastPermissoes.admin = obj.administrador;
      this.$store.state.fastPermissoes.edita_cursos = obj.edita_cursos;
      this.$store.state.fastPermissoes.edita_usuarios = obj.edita_usuarios;
      this.$store.state.fastPermissoes.edita_admins = obj.edita_admins;
      this.$store.state.fastPermissoes.edita_alunos = obj.edita_alunos;
      this.$store.state.fastPermissoes.edita_professores =
        obj.edita_professores;
      this.$store.state.fastPermissoes.edita_plataforma = obj.edita_plataforma;
      this.$store.state.fastPermissoes.edita_matriz = obj.edita_matriz;
      this.$store.state.fastPermissoes.edita_financeiro = obj.edita_financeiro;

      if (obj.ativo != "S") window.location.href = "/minhas-plataformas";

      if (obj.id_usuario) {
        if (!obj.nome_usuario) obj.nome_usuario = "aluno";
        let nomeUsuario = obj.nome_usuario.split(" ");
        if (nomeUsuario.length) {
          this.nomeUsuario = nomeUsuario[0];
        } else {
          this.nomeUsuario = obj.nome_usuario;
        }
        this.getMeusCertificados();
      }
    },
    async getFastPersonalizacao() {
      try {
        let resp = await fetch(
          settings.endApiFastEad +
            "api/fast_personalizacao/lista?id_plataforma=" +
            this.$route.params.id_plataforma,
          this.fastAjaxOptions("GET")
        );
        let json = await resp.json();
        let obj = Array.from(json);
        if (obj.length) this.fastPersonalizacao = obj[0];
      } catch (e) {
        console.log("Erro", e);
      }
    },
    async getMeusCertificados() {
      try {
        let resp = await fetch(
          settings.endApiFastEad +
            "api/fast_plataforma_live/lista_usuario?id_live=0",
          this.fastAjaxOptions("GET")
        );
        let json = await resp.json();
        let obj = Array.from(json);
        if (obj.length) {
          this.fastLiveTotal = obj;
          this.fastLiveFiltro = obj;
        }

        // Informa ao component pai para interromper o loading
        this.$store.state.fastCarregando = false;

        console.log("getMeusCertificados", obj);
      } catch (e) {
        console.log("Erro", e);
      }
    },
  },
};
</script>

<style></style>
